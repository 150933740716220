import React from "react"
import "./SectionContentSniper.css"
import OrderList from "../OrderList/OrderList"

const SectionContentSniper = (props) => {
    const btnColor = props.btnColor
    const styleBtn = {
        color: btnColor,
    }
    const sectionBackground = {
        backgroundColor: props.sectionBackgroundColor,
        backgroundImage: props.sectionBackgroundImage,
    }
    //const isConnected = props.isConnected

    //const [isToggled,setIsToggled] = useState(false);

    const youClicked = function () {
        alert("This is just a UI preview. Fully functional demo is coming soon!")
    }

    return (
        <div style={sectionBackground} className="section" id={props.sectionId}>
            <div className="content padding-25">
                <h1 className="h1-sniper">Snipe List</h1>
                <h4>**UI Mock-up. This is not a functional product**</h4>
                <OrderList />
                <h2 onClick={youClicked} style={styleBtn}>
                    ADD NEW SNIPE
                </h2>
            </div>
        </div>

        //-------------TO BE DISPLAYED ONCE ITS FUNCTIONAL-------------------//
        /* {/* <div style={sectionBackground} className='section' id={props.sectionId}>
            {isConnected ?
                <div className="content padding-25" >
                    <h1 className='h1-sniper'>Snipe List</h1>
                    <h4>**UI Mock-up. This is not a functional product**</h4>
                    <OrderList/>
                    <h2 style={styleBtn}>ADD NEW SNIPE</h2>
                </div>
            :
                <div className="content" >
                    <h1>Ooops, you are not connected</h1>
                    <h3>To use Edgy Sniper please connect your wallet first</h3>
                    <h2 onClick={props.connectWalletHandler} style={styleBtn}>CONNECT</h2>
                </div>
            }
            
        </div>*/
    )
}

export default SectionContentSniper
