import React from 'react';
import "./dropdownItem.css"

const DropdownItem = (props) => {

    const text = props.text
    const icon = props.icon
    const link = props.link

    return (
        <a href={link} >
            <li>
                <span >{text}</span>{icon}
            </li>
        </a>
        
    );
}

export default DropdownItem;

//<a ><li>Home</li></a>
