import React, { useState, useEffect } from "react";
import "./footer.css";
import axios from "axios";
import { useWeb3React } from "@web3-react/core";
import WalletService from "../../WalletService";

const url =
  "https://api.coingecko.com/api/v3/simple/price?ids=chikn-egg%2Cchikn-feed%2Cavalanche-2%2C&vs_currencies=usd";

const Footer = (props) => {
  const { active, account, chainId } = useWeb3React();
  const [usdcBalance, setUsdcBalance] = useState("0.00");
  const [invalidNetwork, setInvalidNetwork] = useState(false);
  const [connectButtonText, setConnectButtonText] = useState('Connect Wallet');

  //---------------------------------GET LIVE PRICES--------------------------------//

  const [open, setOpen] = useState(false);
  const [coins, setCoins] = useState({
    "avalanche-2": {
      usd: 0,
    },
    "chikn-egg": {
      usd: 0,
    },
    "chikn-feed": {
      usd: 0,
    },
  });

  useEffect(() => {
    axios.get(url).then((res) => {
      setCoins(res.data);
    });
  }, []);

  const fetchData = async () => {
    setUsdcBalance(await WalletService.getUSDCBalance());
  };

  useEffect(() => {
    const getBalance = async () => {
      await fetchData();
    };

    if (window.ethereum) {
      if (window.ethereum.networkVersion != 43114) {
        setInvalidNetwork(true);
        setConnectButtonText('Invalid Network');
      } else {
        setInvalidNetwork(false);
        if(!account) {
          setConnectButtonText('Connect Wallet')
        }
      }
    }

    setUsdcBalance(getBalance);
  }, [account, setUsdcBalance]);

  useEffect(() => {
    const _fetchData = async () => {
      await fetchData();
    };

    if (window.ethereum) {
      if (window.ethereum.networkVersion != 43114) {
        setInvalidNetwork(true);
        setConnectButtonText('Invalid Network');
      } else {
        setInvalidNetwork(false);
        if(!account) {
          setConnectButtonText('Connect Wallet')
        }
      }
    }

    const timer = setTimeout(() => {
      _fetchData();
    }, 2500);
  });

  useEffect(() => {
    if (window.ethereum) {
      if (window.ethereum.networkVersion != 43114) {
        setInvalidNetwork(true);
        setConnectButtonText('Invalid Network');
      }
    }
  }, []);

  //---------------------------------GET LIVE PRICES--------------------------------//

  const priceAlign = {
    alignSelf: "center",
    marginLeft: "5px",
  };

  return (
    <div className="footer">
      <div className="footer-container">
        <div className="left-footer">
          <ion-icon class="footer-icon" name="wallet-outline"></ion-icon>
          {active && !invalidNetwork ? (
            <p>
              {account.substring(0, 4) + "..." + account.substring(38, 42)}│
              {usdcBalance ? parseFloat(usdcBalance).toFixed(3) : "0.00"} USDC
            </p>
          ) : (
            <p onClick={async () => await props.connectWalletHandler()}>
              {connectButtonText}
            </p>
          )}
        </div>

        <div
          className="right-footer"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <p>Live Prices</p>
          <ion-icon
            class="footer-icon"
            name="ellipsis-vertical-outline"
          ></ion-icon>
        </div>
        <div className={`right-footer-table ${open ? "active" : "inactive"}`}>
          <ul>
            {/* <li>
              {" "}
              
              <img
                alt=""
                id="logoicon"
                src="./img/logo-dark 3.svg"
                width="20px"
              ></img>
              <span style={priceAlign} id="common">
                13
              </span>
            </li> */}
            <li>
              {" "}
              {/* Avax Price*/}
              <img
                alt=""
                id="logoicon"
                src="./img/avaxlogo.webp"
                width="20px"
              ></img>
              <span style={priceAlign} id="avax">
                {coins["avalanche-2"].usd}
              </span>
            </li>
            <li>
              {" "}
              {/* $EGG price */}
              <img
                alt=""
                id="logoicon"
                src="./img/egglogo.webp"
                width="20px"
              ></img>
              <span style={priceAlign} id="egg">
                {coins["chikn-egg"].usd}
              </span>
            </li>
            {/* <li><img alt="" id="logoicon" src="./img/feedlogo.webp" width="16px"></img><span id="feed">{coins["chikn-feed"].usd}</span></li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
