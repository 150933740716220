import React, { useEffect, useState } from "react"
import "./SectionContentMint.css"
import { Slider } from "@material-ui/core"
import { createTheme, ThemeProvider } from "@material-ui/core/styles"
import { useWeb3React } from "@web3-react/core"
import WalletService from "../../WalletService"

const SectionContentMint = (props) => {
    const { active, account } = useWeb3React()
    const [usdcAllowance, setUsdcAllowance] = useState("0")
    const [usdcBalance, setUsdcBalance] = useState("0")
    const [isWhitelisted, setIsWhitelisted] = useState(false)
    const [mintCount, setMintCount] = useState("0")
    const [whitelistEnforced, setWhitelistEnforced] = useState(true)
    const [mintPaused, setMintPaused] = useState(true)

    const btnColor = props.btnColor
    const styleBtn = {
        color: btnColor,
    }
    const sectionBackground = {
        backgroundColor: props.sectionBackgroundColor,
        backgroundImage: props.sectionBackgroundImage,
    }

    //SLIDER STYLES
    const muiThemeMint = createTheme({
        components: {
            MuiSlider: {
                styleOverrides: {
                    thumb: {
                        color: "#6f6de9",
                        marginTop: "-10px",
                        width: "30px",
                        height: "30px",
                        border: "4px solid white",
                        top: "25px",
                    },
                    track: {
                        color: "white",
                        height: "10px",
                        borderRadius: "10px",
                    },
                    rail: {
                        color: "white",
                        height: "10px",
                        borderRadius: "10px",
                        opacity: "0.2",
                    },
                    mark: {
                        color: "white",
                        width: "5px",
                        height: "5px",
                        borderRadius: "5px",
                    },

                    valueLabel: {
                        "&::before": {
                            position: "absolute",

                            width: "1px",
                            height: "1px",
                            borderRadius: "5px",
                            color: "ffffff0",
                            top: "22px",
                        },
                        color: "#6f6de9",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        fontSize: "15px",
                        fontWeight: "bold",
                        scale: "1.2",
                        marginBottom: "3px",
                        width: "40px",
                        height: "40px",
                        top: "-2px",
                    },
                },
            },
        },
    })

    //SLIDER VALUE
    const [value, setValue] = useState(1)
    const handleSliderChange = (event, newValue) => {
        setValue(newValue)
    }
    const handleOnClickChange = (amount) => {
        setValue(amount)
    }

    //MINTING CONFIRMATION
    /* const numberofMinted = () => {
    alert("You just minted " + value + " Egdy Eggs! Enjoy ser!");
  }; */

    const fetchData = async () => {
        setUsdcAllowance(await WalletService.getUSDCAllowance())
        setUsdcBalance(await WalletService.getUSDCBalance())
        setIsWhitelisted(await WalletService.isWhitelisted())
        setMintCount(await WalletService.getMintCount())
        setWhitelistEnforced(await WalletService.getWhitelistActive())
        setMintPaused(await WalletService.getMintPaused())
    }

    useEffect(() => {
        const _fetchData = async () => {
            await fetchData()
        }

        _fetchData()
    }, [account, active])

    useEffect(() => {
        const _fetchData = async () => {
            await fetchData()
        }

        const timer = setTimeout(() => {
            _fetchData()
        }, 2500)
    })

    const handleMint = async () => {
        if (parseFloat(usdcAllowance) <= parseFloat(usdcBalance)) {
            await WalletService.approveUSDC()
        } else {
            await WalletService.mint(value)
        }
    }

    /* const youClicked = function () {
        alert("ugh, you clicked - you might want to get that checked out")
    } */

    return (
        <div style={sectionBackground} className="section" id={props.sectionId}>
            {mintPaused ? (
                <div className="content">
                    <h1>Public Mint is running</h1>
                    <p>$88 USDC per Egg. Don't forget to keep some gas.</p>
                    <h2 onClick={async () => await props.connectWalletHandler()} style={styleBtn}>
                        CONNECT
                    </h2>
                </div>
            ) : (
                <div>
                    {active ? (
                        <div className="content">
                            {whitelistEnforced ? <h1>{isWhitelisted ? "EGGCITING!!! 88 USDC per Egg." : "Sorry, You are not whitelisted!"}</h1> : <h1>Public Sale has Started! 88 USDC per Egg.</h1>}
                            <div className="stats-wrapper">
                                <h3>
                                    <span>{mintCount} / 10 000</span> Eggs minted
                                </h3>
                            </div>
                            <div className="slider">
                                <ThemeProvider theme={muiThemeMint}>
                                    <Slider defaultValue={1} value={value} onChange={handleSliderChange} step={1} min={1} max={20} marks={false} valueLabelDisplay="on" />
                                </ThemeProvider>
                                <div className="preset-buttons-wrapper">
                                    <button onClick={() => handleOnClickChange(1)} className="button-preset">
                                        1
                                    </button>
                                    <button onClick={() => handleOnClickChange(6)} className="button-preset">
                                        6
                                    </button>
                                    <button onClick={() => handleOnClickChange(12)} className="button-preset">
                                        12
                                    </button>
                                    <button onClick={() => handleOnClickChange(20)} className="button-preset">
                                        20
                                    </button>
                                </div>
                            </div>

                            <h2 onClick={async () => handleMint()} style={styleBtn}>
                                {parseFloat(usdcAllowance) > parseFloat(usdcBalance) ? "Mint" : "Approve"}
                            </h2>
                        </div>
                    ) : (
                        <div className="content">
                            <h1>Ooops, you are not connected</h1>
                            <p>To mint an Edgy Egg please connect your wallet first</p>
                            <h2 onClick={props.connectWalletHandler} style={styleBtn}>
                                CONNECT
                            </h2>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default SectionContentMint
