import React, { useEffect, useState } from "react"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"
import { ethers } from "ethers"
import { InjectedConnector } from "@web3-react/injected-connector"
import WalletService from "../WalletService"
import { useWeb3React } from "@web3-react/core"
import SectionContentHome from "./SectionContentHome/SectionContentHome"
import SectionContentMint from "./SectionContentMint/SectionContentMint"
import SectionContentDapp from "./SectionContentDapp/SectionContentDapp"
import SectionContentSniper from "./SectionContentSniper/SectionContentSniper"
import SectionContentEggmarket from "./SectionContentEggmarket/SectionContentEggmarket"
import SectionContentTeam from "./SectionContentTeam/SectionContentTeam"
import Web3 from "web3"
import "../tailwind.output.css"

const App = () => {
    const { active, account, library, activate } = useWeb3React()

    const bgColor1 = "#c1a84f"
    const bgColor2 = "#1190a5"
    const bgColor3 = "#2a99c4"
    const bgColor4 = "#7051d1"
    const bgColor5 = "#878162"
    const bgColor6 = "#d34fb2"

    ///--------------------------------METAMASK CONNECTION----------------------------///

    //VARIABILES
    const [errorMsg, setErrorMsg] = useState(null)
    const [address, setAddress] = useState("default address")
    const [balance, setBalance] = useState(null)
    const [connectButtonText, setConnectButtonText] = useState("Connect Wallet")
    const [isConnected, setIsConnected] = useState(false)

    const injected = new InjectedConnector({
        supportedChainIds: [43114],
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const connect = async () => {
        try {
            if (window.ethereum.networkVersion != 43114) {
                try {
                    await window.ethereum.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: Web3.utils.toHex(43114) }],
                    })
                } catch (error) {
                    console.error(error)
                }
            }
            await activate(injected)
            await connectWithInterface()
            setAddress(`${account.substring(0, 4)}...${account.substring(38, 42)}`)
            console.log(address)
        } catch (err) {
            console.log(err)
        }
    }

    const connectWithInterface = async () => {
        await WalletService.setupConnection(library, account)
    }
    //CHECK FOR MM
    const connectWalletHandler = async () => {
        await connect()
    }

    useEffect(() => {
        if (account) {
            WalletService.setupConnection(library, account)
        } else {
            connect()
        }
    }, [account, library])

    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on("chainChanged", () => {
                // chainChangedHandler();
            })
            window.ethereum.on("accountsChanged", () => {
                //window.location.reload();
                // connectWalletHandler();
            })

            // Your extra code here
        }
    })

    ///--------------------------------METAMASK CONNECTION----------------------------///

    return (
        <div className="wrapper">
            <Header className="header" />
            <div className="container min-w-full">
                {" "}
                {/* className="container" */}
                <SectionContentEggmarket sectionId={"eggchecker"} sectionBackgroundColor={bgColor2} sectionBackgroundImage={"url(../img/shipping-egg.webp)"} btnColor={bgColor2} connectWalletHandler={connectWalletHandler} isConnected={isConnected}></SectionContentEggmarket>
                <SectionContentHome sectionId={"home"} sectionBackgroundColor={bgColor6} sectionBackgroundImage={"url(../img/bg-01.webp)"} btnColor={bgColor6}></SectionContentHome>
                <SectionContentMint
                    sectionId={"mint"}
                    sectionBackgroundColor={bgColor4}
                    sectionBackgroundImage={"url(../img/egg_mint.webp)"}
                    btnColor={bgColor4}
                    isConnected={isConnected}
                    address={address}
                    balance={balance}
                    connectButtonText={connectButtonText}
                    formatedAddress={address}
                    connectWalletHandler={connectWalletHandler}
                ></SectionContentMint>
                <SectionContentDapp
                    sectionId={"claim"}
                    sectionBackgroundColor={bgColor3}
                    sectionBackgroundImage={"url(../img/bg-03.webp)"}
                    btnColor={bgColor3}
                    isConnected={isConnected}
                    address={address}
                    balance={balance}
                    connectButtonText={connectButtonText}
                    formatedAddress={address}
                    connectWalletHandler={connectWalletHandler}
                ></SectionContentDapp>
                <SectionContentSniper sectionId={"sniper"} sectionBackgroundColor={bgColor5} sectionBackgroundImage={"url(../img/bg-sniper.webp)"} btnColor={bgColor5} connectWalletHandler={connectWalletHandler} isConnected={isConnected}></SectionContentSniper>
                <SectionContentTeam sectionId={"team"} sectionBackgroundColor={bgColor1} sectionBackgroundImage={"url(../img/bg-02.webp)"} btnColor={bgColor1} connectWalletHandler={connectWalletHandler} isConnected={isConnected}></SectionContentTeam>
            </div>

            <Footer isConnected={isConnected} address={address} balance={balance} connectButtonText={connectButtonText} formatedAddress={address} connectWalletHandler={connectWalletHandler}></Footer>
        </div>
    )
}

export default App
