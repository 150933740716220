import React, { useState } from "react"
import MemberModal from "./MemberModal"
import "./SectionContentTeam.css"
import TeamMember from "./TeamMember"

const SectionContentTeam = (props) => {
    /* const btnColor = props.btnColor
    const styleBtn = {
        color: btnColor,
    } */
    const sectionBackground = {
        backgroundColor: props.sectionBackgroundColor,
        backgroundImage: props.sectionBackgroundImage,
    }
    /* const isConnected = props.isConnected */

    /* const [isToggled,setIsToggled] = useState(false); */

    const [openModal, setOpenModal] = useState(false)
    const [member, setMember] = useState(null)

    return (
        <div style={sectionBackground} className="section-team" id={props.sectionId}>
            <div className="content-team padding-25">
                <h1 className="h1-sniper">Know Your Team</h1>
                <p className="team-desc">
                    The EdgyEggs team is a well-balanced operation that utilises a wide variety of skill sets that both complement and overlap to allow us to be working 24/7. This dynamic flat structure allows for the most well-rounded discussions. Embracing the full spirit of the Web3 working
                    world, this global team operates across the US, UK, Europe and UAE.{" "}
                </p>
                <div className="team-container">
                    <TeamMember
                        imageSource="https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/ondrej.png"
                        title="Egg Artist"
                        subtitle="Ondrej"
                        open={() => {
                            setOpenModal(true)
                            setMember(0)
                        }}
                        openModal={openModal}
                    />

                    <TeamMember
                        imageSource="https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/chameleon.png"
                        title="Egg Architect"
                        subtitle="Chameleon"
                        open={() => {
                            setOpenModal(true)
                            setMember(2)
                        }}
                        openModal={openModal}
                    />
                    <TeamMember
                        imageSource="https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/egglizabeth.png"
                        title="Egg Relations"
                        subtitle="Queen Egglizabeth"
                        open={() => {
                            setOpenModal(true)
                            setMember(3)
                        }}
                        openModal={openModal}
                    />
                    <TeamMember
                        imageSource="https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/sirtris.png"
                        title="Senior Egg Developer"
                        subtitle="Sir Tris"
                        open={() => {
                            setOpenModal(true)
                            setMember(4)
                        }}
                        openModal={openModal}
                    />
                </div>
                <MemberModal member={member} open={openModal} close={() => setOpenModal(false)} />
                {/* <OrderList/>
                <h2 style={styleBtn}>ADD NEW SNIPE</h2> */}
            </div>
        </div>
    )
}

export default SectionContentTeam
