import React from "react"
import ReactDOM from "react-dom/client"
import App from "./components/App"
import "./styles/global.css"
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from 'web3';

//ReactDOM.render(<App /> , document.getElementById("root"))

const root = ReactDOM.createRoot(document.getElementById("root"));

function getLibrary(provider) {
    return new Web3(provider);
}

root.render(
    
    <React.StrictMode>
        <Web3ReactProvider getLibrary={getLibrary}>
            <App/>
        </Web3ReactProvider>
    </React.StrictMode>
);