import React from "react"

const MemberModal = ({ open, close, member }) => {
    const team = [
        {
            image: "https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/ondrej.png",
            title: "Egg Artist",
            subtitle: "Ondrej",
            discord: "@OndrejP#9006",
            description:
                "Our incredibly talented NFT artist, after numerous ideas and designs, finally completed his first full collection in EdgyEggs. His portfolio consists of 3D animated 4K design loops also created with meticulous detail and continues to impress with new designs every day. With a background in the CGI industry, running a CGI studio with multiple employees of equal skill and working with major players in multiple industries, Ondrej has a vast variety of skills to draw on to meet the needs of EdgyEggs on the fly and long-term planned visual designs.",
        },
        {
            image: "https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/jimbob.png",
            title: "Egg Developer",
            subtitle: "Jimbob",
            discord: "@Jimbob | BigChikn |EdgyEggs#7459",
            description:
                "As an OG Chikn investor, Jimbob advises strategy and scripting for the ecosystem. From humble beginnings as a moderator in the crypto world, Jimbob has since become a full-time developer learning under the wing of our senior developer. Jimbob’s days are spent honing skills in: React, Node.js, JavaScript, HTML5, CSS, SCSS, Express and MongoDB, Python and Web3/solidity. He was instrumental in the co-creation of the EdgyEggs contract and designed the functionality of the contract.",
        },
        {
            image: "https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/chameleon.png",
            title: "Egg Architect",
            subtitle: "Chameleon",
            discord: "@Karma Chameleon#8874",
            description:
                "With 10+ years experience and a BAHons in advertising and marketing, Chameleon both created his own business and worked with large billion euro global accounts, managing campaign launches for Ford as well as regular content production, audience analysis and SEO. From video/sound design, and game mechanics development in previous work to technical analysis and campaign budgeting; Chameleon’s experience is ideal for building teams. Beginning with stocks and day trading lead to crypto and NFTs. Now retired as a full-time investor, Chameleon is dedicated 24/7 to building EdgyEggs.",
        },
        {
            image: "https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/egglizabeth.png",
            title: "Egg Relations",
            subtitle: "Queen Egglizabeth",
            discord: "@Queen Egglizabeth#8888",
            description:
                "Egglizabeth has a Bachelor's degree in education and has spent 19 years as a manager, project manager, social media manager, influencer & and talent recruiter. Working with companies all over the globe such as Adidas, Nike, Amazon, FOX, FIAT, Audi, Samsung, Nintendo, and so many... many more. Egglizabeth brings a wealth of experience to the team with outside-the-box thinking, fun new ideas and invaluable contacts with brands.",
        },
        {
            image: "https://d70vztfbdnnnq.cloudfront.net/fit-in/400x400/profile/sirtris.png",
            title: "Senior Egg Developer",
            subtitle: "Sir Tris",
            discord: "",
            description:
                "One of the best developers we’ve had the pleasure to meet and work with, in our time in this space. Sir Tris is a true knight, defender of eggy exploits, and mastermind of the final contract we use today. With over 15 years of experience across the full spectrum as a Full-Stack developer, Sir Tris has seen more than most and still finds new ways to innovate with new ideas. Tris is active and engaged in the crypto space always looking to build and improve, dedicated to seeing EdgyEggs succeed.",
        },
    ]

    if (!open) return null

    return (
        <div className="overlay">
            <span onClick={close} className="goBackArrow"></span>
            <div className="modalContainer">
                <div className="modalContent">
                    <div className="modalContent__image">
                        <img alt="" src={team[member].image}></img>
                    </div>
                    <div className="modalContent__info">
                        <div className="memberTitles">
                            <span className="memberTitles__subtitle">{team[member].subtitle}</span>
                            <h2>{team[member].title}</h2>
                        </div>
                        <h4>{team[member].discord}</h4>
                        <p>{team[member].description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberModal
