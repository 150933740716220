import { USDC_ABI, MINT_ABI } from "./abi";

const WalletService = {
  provider: null,
  connectedAddress: "",
  ethereum: null,

  USDC_ADDRESS: "0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E",
  usdcContract: null,

  MINT_ADDRESS: "0x07d4D391DF0a13702a11eb8bA75185B85543c013",
  mintContract: null,

  // Setup
  async setupConnection(provider, address) {
    this.provider = provider;
    this.connectedAddress = address;
    this.ethereum = provider.givenProvider;

    this.usdcContract = await new this.provider.eth.Contract(
      JSON.parse(USDC_ABI),
      this.USDC_ADDRESS
    );
    this.mintContract = await new this.provider.eth.Contract(
      JSON.parse(MINT_ABI),
      this.MINT_ADDRESS
    );
  },

  // Read Functions
  async getAvaxBalance() {
    return (await this.provider.eth.getBalance(this.connectedAddress)) * 1e-18;
  },

  async getWhitelistActive(){
    return await this.mintContract.methods.whitelistEnforced().call();
  },

  async getMintPaused(){
    return await this.mintContract.methods.paused().call();
  },

  // Read Functions
  async getMintCount() {
    return await this.mintContract.methods.totalSupply().call();
  },

  async getUserNFTCount() {
    return await this.mintContract.methods
      .balanceOf(this.connectedAddress)
      .call();
  },

  async getWormClaimable() {
    return await this.mintContract.methods.withdrawableDividendOf(this.connectedAddress).call() * 1e-18;
  },

  // USDC Functions
  async getUSDCAllowance() {
    return (
      (await this.usdcContract.methods
        .allowance(this.connectedAddress, this.MINT_ADDRESS)
        .call()) * 1e-6
    );
  },

  async getUSDCBalance() {
    return (
      (await this.usdcContract.methods
        .balanceOf(this.connectedAddress)
        .call()) * 1e-6
    );
  },

  async approveUSDC() {
    try {
      const callData = await this.usdcContract.methods
        .approve(
          this.MINT_ADDRESS,
          "115792089237316195423570985008687907853269984665640564039457584007913129639935"
        )
        .encodeABI();

      this.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              data: callData,
              from: this.connectedAddress,
              to: this.USDC_ADDRESS,
            },
          ],
        })
        .then((txHash) => {
          return txHash;
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      console.log(err);
    }
  },

  // MINT Functions

  async isWhitelisted() {
    return await this.mintContract.methods
      .isWhitelisted(this.connectedAddress)
      .call();
  },

  async mint(amount) {
    try {
      const callData = await this.mintContract.methods.mint(amount).encodeABI();

      this.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              data: callData,
              from: this.connectedAddress,
              to: this.MINT_ADDRESS,
            },
          ],
        })
        .then((txHash) => {
          return txHash;
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      console.log(err);
    }
  },

  async claim() {
    try {
      const callData = await this.mintContract.methods.claim().encodeABI();

      this.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              data: callData,
              from: this.connectedAddress,
              to: this.MINT_ADDRESS,
            },
          ],
        })
        .then((txHash) => {
          return txHash;
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      console.log(err);
    }
  },

  async harvest() {
    try {
      const callData = await this.mintContract.methods.harvest().encodeABI();

      this.ethereum
        .request({
          method: "eth_sendTransaction",
          params: [
            {
              data: callData,
              from: this.connectedAddress,
              to: this.MINT_ADDRESS,
            },
          ],
        })
        .then((txHash) => {
          return txHash;
        })
        .catch((err) => {
          return err;
        });
    } catch (err) {
      console.log(err);
    }
  },
};

export default WalletService;
