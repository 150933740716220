import React from 'react'
import './OrderListItem.css'


const OrderListItem = (props) => {
  return (
    <div className="order-list-item">
        <p className="item-number">{props.number}</p>
        <span className={props.status}></span>
        <p className='order-list-item-status-label'>{props.statusLabel}</p>
        <p className='order-list-item-desc'>{props.desc}</p>
        <p className='order-list-item-network'>{props.network}</p>
        <p className='order-list-item-tx'>{props.tx}</p>
        <span className='order-list-item-remove'></span>
    </div>
  )
}

export default OrderListItem