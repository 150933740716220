import React, { useState, useEffect } from "react";
import "./SectionContentDapp.css";
import { Slider } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { useWeb3React } from "@web3-react/core";
import WalletService from "../../WalletService";

const SectionContentDapp = (props) => {
  const { active, account } = useWeb3React();
  const [userNFTBalance, setUserNFTBalance] = useState('0');
  const [claimableWorm, setClaimableWorm] = useState('0');

  const btnColor = props.btnColor;
  const styleBtn = {
    color: btnColor,
  };
  const sectionBackground = {
    backgroundColor: props.sectionBackgroundColor,
    backgroundImage: props.sectionBackgroundImage,
  };


  const muiThemeDapp = createTheme({
    components: {
      MuiSlider: {
        styleOverrides: {
          thumb: {
            color: "#4fb6de",
            marginTop: "-10px",
            width: "30px",
            height: "30px",
            border: "4px solid white",
            top: "25px",
          },
          track: {
            color: "white",
            height: "10px",
            borderRadius: "10px",
          },
          rail: {
            color: "white",
            height: "10px",
            borderRadius: "10px",
            opacity: "0.2",
          },
          mark: {
            color: "white",
            width: "5px",
            height: "5px",
            borderRadius: "5px",
          },

          valueLabel: {
            "&::before": {
              position: "absolute",

              width: "1px",
              height: "1px",
              borderRadius: "5px",
              color: "ffffff0",
              top: "22px",
            },
            color: "#4fb6de",
            backgroundColor: "white",
            borderRadius: "20px",
            fontSize: "15px",
            fontWeight: "bold",
            scale: "1.2",
            marginBottom: "3px",
            width: "40px",
            height: "40px",
            top: "-2px",
          },
        },
      },
    },
  });

  //SLIDER VALUE
  const [value, setValue] = useState(50);
  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleOnClickChange = value => {
    setValue(value);
  }

  const fetchData = async () => {
    setUserNFTBalance(await WalletService.getUserNFTCount());
    setClaimableWorm(await WalletService.getWormClaimable());
  }

  useEffect(() => {
    const _fetchData = async () => {
        await fetchData();
    };

    _fetchData();
  }, [account, active]);

  useEffect(() => {
    const _fetchData = async () => {
        await fetchData();
    };

    const timer = setTimeout(() => {
        _fetchData();
      }, 2500);
  })

  //MINTING CONFIRMATION
  const claim = async () => {
      await WalletService.claim();
  };

  //HARVEST
  const harvest = async () => {
    await WalletService.harvest()
  }

  const edgyEggs = 13;
  const worm = 123;

  const pTag = {
    fontSize: "0.73rem",
    padding: "5px",
    marginTop: "-10px"
  }
  const divFlex = {
    display: "flex",
    flexDirection: "row"
  }

  return (
    <div style={sectionBackground} className="section" id={props.sectionId}>
      {active ? (
        <div className="content">
          <h1>Check your earnings</h1>
          <div className="stats-wrapper">
            <h3>
              Edgy Eggs: <span>{userNFTBalance}</span>
            </h3>
            <h3>
              Worm to claim: <span>{parseFloat(claimableWorm).toFixed(4)}</span>
            </h3>
            <p style={pTag} >If no Worm on Claim, click Harvest. This button harvests The EdgyEggs Farm</p>
          </div>
          
          <div style={divFlex}>
            <h2 onClick={async () => await harvest()} style={styleBtn}>
              HARVEST 
            </h2>
            <h2 onClick={async () => await claim()} style={styleBtn}>
              CLAIM
            </h2>
          </div>
          
        </div>
      ) : (
        <div className="content">
          <h1>Ooops, you are not connected</h1>
          <p>To mint an Edgy Egg please connect your wallet first</p>
          <h2 onClick={props.connectWalletHandler} style={styleBtn}>
            CONNECT
          </h2>
        </div>
      )}
    </div>
  );
};

export default SectionContentDapp;
