import React from 'react';
import "./SectionContentHome.css"

const SectionContentHome = (props) => {

    const btnColor = props.btnColor

    const styleBtn = {
        color: btnColor
    }
    const styleBtnSecondary = {
        backgroundColor: btnColor,
        color: "white",
        border: "2px solid white",
        fontWeight: "500",
        paddingTop: "8px",
        paddingBottom: "8px",
        paddingLeft: "20px",
        paddingRight: "20px" 
    }
    const sectionBackground = {
        backgroundColor: props.sectionBackgroundColor,
        backgroundImage: props.sectionBackgroundImage
    }

    const pStyle = {
        paddingBottom: "5px"
    }
    const aStyle = {
        textDecoration: "none"
    }
    const btnDiv = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        alignContent: "center"
    }

    return (
        <div style={sectionBackground} className='section' id={props.sectionId}>
            <div className="content" >
                <h1>LET'S GET YOLKED!</h1>
                <p style={pStyle} >World's first Eggnite Chikn partnership and Yield earning Egg project on Avax.</p>
                <p>LP Farming, NFT and Token Sniper, 3D Game and AR/VR NFTs.</p>
                <div style={btnDiv} >
                    <a style={aStyle} href='#mint' ><h2 style={styleBtn}>MINT NOW</h2></a>
                    <a style={aStyle} href='https://eggbook.edgyeggs.wtf/' ><h2 style={styleBtnSecondary}>Learn more</h2></a>
                </div>
                

            </div>
        </div>
    );
}

export default SectionContentHome;