import React from 'react'
import './OrderList.css'
import OrderListItem from '../OrderListItem/OrderListItem'


const OrderList = () => {
  return (
    <div className="order-list-wrapper">

        <div className="order-list-top">
            <p className="item-number">#</p>
            {/* <span className='status' ></span> */}
            <p className='order-list-item-status-label'>Status</p>
            <p className='order-list-item-desc'>Description</p>
            <p className='order-list-item-network'>Network</p>
            <p className='order-list-item-tx'>Tx</p>
            {/* <span className='order-list-item-remove-menu'></span> */}
        </div>

        <OrderListItem
            number={'#1'}
            status={'status status-color-waiting'}
            statusLabel={'Waiting'}
            desc={'Edgy Eggs Mint'}
            network={'Avax'}
            tx={'Tx'}
        >
        </OrderListItem>

        <OrderListItem
            number={'#2'}
            status={'status status-color-filled'}
            statusLabel={'Filled'}
            desc={'BAYC floor'}
            network={'ETH'}
            tx={'Tx'}
        >
        </OrderListItem>

        <OrderListItem
            number={'#3'}
            status={'status status-color-filled'}
            statusLabel={'Filled'}
            desc={'Sweep that Chikn'}
            network={'Avax'}
            tx={'Tx'}
        >
        </OrderListItem>

        <OrderListItem
            number={'#4'}
            status={'status status-color-waiting'}
            statusLabel={'Waiting'}
            desc={'Rooster floor'}
            network={'Avax'}
            tx={'Tx'}
        >
        </OrderListItem>

        {/* <OrderListItem
            number={'#5'}
            status={'status status-color-failed'}
            statusLabel={'Waiting'}
            desc={'Chikn floor'}
            network={'Avax'}
            tx={'Tx'}
        >
        </OrderListItem>

        <OrderListItem
            number={'#6'}
            status={'status status-color-filled'}
            statusLabel={'Waiting'}
            desc={'Ruggy Rug'}
            network={'ETH'}
            tx={'Tx'}
        >
        </OrderListItem>

        <OrderListItem
            number={'#4'}
            status={'status status-color-waiting'}
            statusLabel={'Waiting'}
            desc={'Chameleon Tongue NFTs'}
            network={'ETH'}
            tx={'Tx'}
        >
        </OrderListItem> */}
        
    </div>
  )
}

export default OrderList