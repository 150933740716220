/* import TopSearchBar from "./TopSearchBar"
import Sidebar from "./Sidebar"
import LazyLoad from "react-lazy-load"
import Img from "react-optimized-image"
import { MdMenuOpen } from "react-icons/md"
import { CgMenuBoxed } from "react-icons/cg" */
/* import { IoBandageOutline } from "react-icons/io5" */
import React, { useState, useEffect, useMemo } from "react"
import axios from "axios"
import "./SectionContentEggmarket.css"
import InfiniteScroll from "react-infinite-scroll-component"
import { PiSelectionBackgroundBold } from "react-icons/pi"
import { BsBandaid, BsEgg } from "react-icons/bs"
import { GiBelt, GiNecklace } from "react-icons/gi"
import { LuCrown } from "react-icons/lu"
import { IoHandRightOutline, IoHandLeftOutline } from "react-icons/io5"
import { GiPearlNecklace } from "react-icons/gi"
import { PiUserBold } from "react-icons/pi"
import { AiOutlineEye } from "react-icons/ai"
import { BsHeadsetVr } from "react-icons/bs"
import { GiLips } from "react-icons/gi"
import { BsEggFried } from "react-icons/bs"
import { BiFilterAlt } from "react-icons/bi"
import { MdClear } from "react-icons/md"
import { LiaSearchSolid } from "react-icons/lia"
import { GiRank3 } from "react-icons/gi"
import { TbMilitaryRank } from "react-icons/tb"
import { FaRankingStar } from "react-icons/fa6"
import { SiSecurityscorecard } from "react-icons/si"

const SectionContentEggmarket = (props) => {
    /* const btnColor = props.btnColor
    const styleBtn = {
        color: btnColor,
    } */
    const sectionBackground = {
        backgroundColor: props.sectionBackgroundColor,
        //backgroundImage: props.sectionBackgroundImage
    }
    /* const width = {
        width: "100%",
        textAlign: "center",
    } */
    /* const isConnected = props.isConnected */

    //const [isToggled,setIsToggled] = useState(false);
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true)
                const result = await axios.get(`https://api.edgyeggs.wtf/metadata`)
                setMetadata(result.data)
                setIsLoading(false)
            } catch (error) {
                console.error("Error fetching data", error)
            }
        }
        fetchData()
    }, [])
    const handleSearchChange = (event) => {
        setSearchValue(event.target.value)
        console.log(searchValue)
    }
    /* const handleClearSearch = () => {
        setSearchValue("")
    } */
    const fetchMore = () => {
        if (limit < metadata.length) {
            setTimeout(() => {
                setLimit(limit + 40)
            }, 1000)
        } else {
            setHasMore(false)
        }
    }
    const [metadata, setMetadata] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [hasMore, setHasMore] = useState(true)
    const [limit, setLimit] = useState(50)
    const [selectedRarity, setSelectedRarity] = useState("")
    const [selectBackground, setSelectBackground] = useState("")
    const [selectedShell, setSelectedShell] = useState("")
    const [selectedExtras, setSelectedExtras] = useState("")
    const [selectedWaist, setSelectedWaist] = useState("")
    const [selectedRightHand, setSelectedRightHand] = useState("")
    const [selectedLeftHand, setSelectedLeftHand] = useState("")
    const [selectedNeck, setSelectedNeck] = useState("")
    const [selectedHead, setSelectedHead] = useState("")
    const [selectedEyes, setSelectedEyes] = useState("")
    const [selectedEyeGear, setSelectedEyeGear] = useState("")
    const [selectedMouth, setSelectedMouth] = useState("")
    const [selectedYolkType, setSelectedYolkType] = useState("")
    //const shouldDisplayClear = searchValue.length > 0

    function clearSearch() {
        setSearchValue("")
    }
    function handleClearRarity() {
        setSelectedRarity("")
    }
    function handleClearBackground() {
        setSelectBackground("")
    }
    function handleClearShell() {
        setSelectedShell("")
    }
    function handleClearExtras() {
        setSelectedExtras("")
    }
    function handleClearWaist() {
        setSelectedWaist("")
    }
    function handleClearRightHand() {
        setSelectedRightHand("")
    }
    function handleClearLeftHand() {
        setSelectedLeftHand("")
    }
    function handleClearNeck() {
        setSelectedNeck("")
    }
    function handleClearHead() {
        setSelectedHead("")
    }
    function handleClearEyes() {
        setSelectedEyes("")
    }
    function handleClearEyeGear() {
        setSelectedEyeGear("")
    }
    function handleClearMouth() {
        setSelectedMouth("")
    }
    function handleClearYolkType() {
        setSelectedYolkType("")
    }
    function handleClearAll() {
        setSearchValue("")
        setSelectedRarity("")
        setSelectBackground("")
        setSelectedShell("")
        setSelectedExtras("")
        setSelectedWaist("")
        setSelectedRightHand("")
        setSelectedLeftHand("")
        setSelectedNeck("")
        setSelectedHead("")
        setSelectedEyes("")
        setSelectedEyeGear("")
        setSelectedMouth("")
        setSelectedYolkType("")
    }

    const filteredMetadata = useMemo(
        () =>
            metadata
                .slice(14)
                .filter(
                    (nft) =>
                        nft.name.includes(searchValue) &&
                        (selectedRarity ? nft.Rarity === selectedRarity : true) &&
                        (selectBackground ? nft.attributes?.[0]?.value === selectBackground : true) &&
                        (selectedShell ? nft.attributes?.[1]?.value === selectedShell : true) &&
                        (selectedExtras ? nft.attributes?.[2]?.value === selectedExtras : true) &&
                        (selectedWaist ? nft.attributes?.[3]?.value === selectedWaist : true) &&
                        (selectedRightHand ? nft.attributes?.[4]?.value === selectedRightHand : true) &&
                        (selectedNeck ? nft.attributes?.[5]?.value === selectedNeck : true) &&
                        (selectedHead ? nft.attributes?.[6]?.value === selectedHead : true) &&
                        (selectedLeftHand ? nft.attributes?.[7]?.value === selectedLeftHand : true) &&
                        (selectedEyes ? nft.attributes?.[8]?.value === selectedEyes : true) &&
                        (selectedEyeGear ? nft.attributes?.[9]?.value === selectedEyeGear : true) &&
                        (selectedMouth ? nft.attributes?.[10]?.value === selectedMouth : true) &&
                        (selectedYolkType ? nft.attributes?.[11]?.value === selectedYolkType : true)
                ),
        [metadata, searchValue, selectedRarity, selectBackground, selectedShell, selectedExtras, selectedWaist, selectedRightHand, selectedLeftHand, selectedNeck, selectedHead, selectedEyes, selectedEyeGear, selectedMouth, selectedYolkType]
    )

    const neckOptions = ["Ava Gold", "Barbed", "Beads", "Diamond Egg", "Dr", "Golden Pearls", "High on Life", "No Neck", "Pearls", "VIE", "Xmas", "Yolk ID"]
    const rarityOptions = ["Eggspendable", "Eggcellent", "Eggceptional", "Eggstraordinary", "Eggotic" /* , "Mythic" */]
    const shellExtrasOptions = ["Edgy Eggs Logo", "Clean Shell", "Crack Repair", "Cracked", "Tally", "Tattoo", "APOA-1", "CCL-19", "CYP-19-A", "FABP-3", "CH-1-KN", "LPL", "OSMR"]
    const waistOptions = ["Bullion Bow", "Floaty", "Fried", "Jello", "Locked Up", "Movie Night", "No Waist", "Not a Floor Dumper", "Speakeasy", "Stud", "Violent Violet Bow", "WWE", "Zombie Bow"]
    const eyeGearOptions = ["3D glasses", "Censored", "Deal With It", "Egg Shades", "EggBeer Glasses", "Golden Deal With It", "Lenon Glasses", "Monocle", "Neon Blue Rave Glasses", "Neon Green Rave Glasses", "Neon Red Rave Glasses", "Neon Yolk Rave Glasses", "No Eye Gear", "Pirate Patch", "VR"]
    const mouthOptions = ["Asthma", "Cigar", "Donut", "Duct Tape", "Dynamite", "Flu", "Fork", "Gag", "Joint", "Lips", "Mustache", "No Mouth", "Pacifier", "Pipe", "Rose", "Smoker", "Snorkel", "The Chordettes", "Whistle"]
    const yolkTypeOptions = ["APOA-1", "CCL-19", "CYP-19-A", "FABP-3", "CH-1-KN", "LPL", "OSMR"]
    const backgroundOptions = [
        "Acid Green",
        "Avacado Toast",
        "Beach Studio",
        "Blue Egg Wallpaper",
        "Bokeh Barn",
        "Bokeh Cafe",
        "Bokeh California",
        "Bokeh City Skyline",
        "Bokeh Desktop",
        "Bokeh Garden",
        "Bokeh Hillside",
        "Bokeh Kitchen",
        "Bokeh Restaurant",
        "Bokeh Seaside",
        "Bokeh Treetop",
        "Bokeh Woodland Disco",
        "Butter 'n' Toast",
        "Comic Wallpaper",
        "Cuphead",
        "Deep Purple",
        "Edgy Eggs",
        "Fried Wallpaper",
        "Fuschia",
        "Graffiti",
        "Green Egg Wallpaper",
        "Green Screen",
        "Happy Breakfast",
        "Midnight Blue",
        "Moon Studio",
        "Mustang Bullitt",
        "Ocean",
        "Plain Black",
        "Plain Blue",
        "Plain Olive",
        "Plain Purple",
        "Plain Red",
        "Plain Teal",
        "Rainbow",
        "Retro Auburn",
        "Retro Autumn Harvest",
        "Retro Avocado",
        "Retro Seaside",
        "Retro Sunset",
        "Yolk Wallpaper",
        "Yolk",
    ]
    const shellOptions = [
        "Cracked 2D",
        "Cracked Artsy",
        "Cracked Black Shell",
        "Cracked Easter",
        "Cracked Egg",
        "Cracked Glass",
        "Cracked Golden Egg",
        "Cracked Chocolate Egg",
        "Cracked Jello Egg",
        "Cracked Rainbow",
        "Cracked Rotten",
        "Cracked White",
        "Full 2D",
        "Full Artsy",
        "Full Black Shell",
        "Full Easter",
        "Full Egg",
        "Full Glass",
        "Full Golden Egg",
        "Full Chocolate Egg",
        "Full Jello Egg",
        "Full Rainbow",
        "Full Rotten",
        "Full White",
        "Open 2D",
        "Open Artsy",
        "Open Black Shell",
        "Open Easter",
        "Open Egg",
        "Open Glass",
        "Open Golden Egg",
        "Open Chocolate Egg",
        "Open Jello Egg",
        "Open Rainbow",
        "Open Rotten",
        "Open White",
    ]
    const rightHandOptions = [
        "Ameerrriiiccaaaa!!",
        "Ashoka Chakra",
        "Avalanche Flag",
        "Avax Balloon",
        "Bok Bok",
        "Boxer",
        "Egg Balloon",
        "Eggemite",
        "Exoskelegg",
        "Fidget Spinner",
        "Fist",
        "Flamey Thing",
        "Floor Dumper",
        "Grenade",
        "Chikn Balloon",
        "Kink",
        "Lollipoop",
        "No Dumping",
        "No Right Hand",
        "Say Eggggg!",
        "Shoot",
        "The Finger",
        "The Great Bloodaxe",
        "Thumbs",
        "Wave",
    ]
    const leftHandOptions = [
        "Avalanche Flag Left",
        "Avax Balloon Left",
        "Battle Fork",
        "Blowtorch",
        "Blue Fidget Spinner",
        "Blue Lollipop",
        "Blue Moon",
        "Boxer Left",
        "Don't Shoot",
        "Dropped Grenaaaade",
        "Egg Balloon Left",
        "Eggemite Spread",
        "Eggson Guitar",
        "Fish",
        "Floor Dumper Left",
        "Green Moon",
        "Handcuffs",
        "Here's Eggy",
        "Chicken",
        "Chikn Balloon Left",
        "Muricaland Flag",
        "No Dumping Please",
        "No Left Hand",
        "Purple Moon",
        "Robot",
        "Salty",
        "Skater",
        "Surfer",
        "Tricolour",
    ]
    const headOptions = [
        "Air Head Backwards Cap",
        "Anatidaephobia",
        "Blue Hair",
        "Brain Flies",
        "Brain Freeze",
        "Brains",
        "Bright Future",
        "Bunny Ears",
        "Burger Brain",
        "Camper",
        "Clown Wig",
        "Collapsed Skull",
        "Doeppler Effect",
        "Donuts Donuts Donuts",
        "Dreadlocks",
        "Ducks Ducks Ducks",
        "Egg Cap",
        "Eggemite Cache",
        "Eggy P.D",
        "Echo Beats by Egg White",
        "Extra Protection",
        "Floppy Hat",
        "Golden Beats by Yolk",
        "Grass",
        "Hard Hat Egg",
        "Hello Sailor",
        "Hercule Poirot",
        "Hollow Bucket",
        "Holy Egg",
        "Knives In",
        "No Head",
        "Not a Sedge Hat",
        "Nuggets of Knowledge",
        "Oil Sheikh",
        "Popcorn Brain",
        "Potassium Brain",
        "Quackers",
        "Rasta Knit",
        "Royal Egg",
        "Sneaky Chameleon",
        "Tastes like Purple",
        "Third Eye",
        "TNT",
        "Toque",
        "Town Shell",
        "Unicorn",
        "What's Under the Hat",
        "Witches Head",
        "Worm",
        "Yarmulke",
        "YMCA",
    ]
    const eyeOptions = [
        "Albino Evil Eyes",
        "Artsy Evil Eyes",
        "Beaten Artsy",
        "Beaten Black",
        "Beaten Easter",
        "Beaten Egg",
        "Beaten Golden",
        "Beaten Chocolate",
        "Beaten Jello",
        "Beaten Plain",
        "Beaten Purple",
        "Closed Artsy Eyes",
        "Closed Black Eyes",
        "Closed Easter Eyes",
        "Closed Egg Eyes",
        "Closed Golden Eyes",
        "Closed Chocolate Eyes",
        "Closed Jello Eyes",
        "Closed Plain Eyes",
        "Closed Purple Eyes",
        "Crazy Artsy",
        "Crazy Black",
        "Crazy Easter",
        "Crazy Egg",
        "Crazy Golden",
        "Crazy Chocolate",
        "Crazy Jello",
        "Crazy Plain",
        "Crazy Purple",
        "Cute Daemon Artsy",
        "Cute Daemon Black",
        "Cute Daemon Easter",
        "Cute Daemon Egg",
        "Cute Daemon Golden",
        "Cute Daemon Chocolate",
        "Cute Daemon Jello",
        "Cute Daemon Plain",
        "Cute Daemon Purple",
        "Dead Egg",
        "Dizzy Artsy Eyes",
        "Dizzy Black Eyes",
        "Dizzy Easter Eyes",
        "Dizzy Egg Eyes",
        "Dizzy Golden Eyes",
        "Dizzy Chocolate Eyes",
        "Dizzy Jello Eyes",
        "Dizzy Plain Eyes",
        "Dizzy Purple Eyes",
        "Easter Evil Eyes",
        "Evil Eyes Black",
        "Evil Eyes",
        "Golden Evil Eyes",
        "Chocolate Evil Eyes",
        "Jello Evil Eyes",
        "Laser Eyes",
        "Murder Eyes Artsy",
        "Murder Eyes Black",
        "Murder Eyes Easter",
        "Murder Eyes Egg",
        "Murder Eyes Golden",
        "Murder Eyes Chocolate",
        "Murder Eyes Jello",
        "Murder Eyes Plain",
        "Murder Eyes Purple",
        "No Sleep Black Eyes",
        "No Sleep Egg Eyes",
        "None",
        "Purple Evil Eyes",
        "Scared Artsy Bitcoin Eyes",
        "Scared Bitcoin Black",
        "Scared Bitcoin Easter Eyes",
        "Scared Bitcoin Egg",
        "Scared Bitcoin Golden Eyes",
        "Scared Bitcoin Chocolate Eyes",
        "Scared Bitcoin Plain Eyes",
        "Scared Jello Bitcoin Eyes",
        "Scared Purple Bitcoin Eyes",
        "Seen Too Much",
        "Suspicious Artsy Eyes",
        "Suspicious Black Eyes",
        "Suspicious Easter Eyes",
        "Suspicious Egg Eyes",
        "Suspicious Golden Eyes",
        "Suspicious Chocolate Eyes",
        "Suspicious Jello Eyes",
        "Suspicious Plain Eyes",
        "Suspicious Purple Eyes",
        "Wired Artsy Eyes",
        "Wired Easter Eyes",
        "Wired Golden Eyes",
        "Wired Chocolate Eyes",
        "Wired Jello Eyes",
        "Wired Purple Eyes",
        "Wired White Eyes",
    ]

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedNft, setSelectedNft] = useState(null)

    const toggleModal = (nft) => {
        setIsModalVisible(!isModalVisible)
        setSelectedNft(nft)
    }

    console.log(selectedNft)

    return (
        <div style={sectionBackground} className="section-eggmarket" id={props.sectionId}>
            {" "}
            {/* className="section-eggmarket" */}
            <div className="content-eggmarket padding-25">
                <div className="flex md:hidden flex-col w-full justify-center items-center h-full">
                    <p className="text-xl">View in desktop mode</p>
                </div>
                <div className="search-content hidden md:flex h-full justify-between gap-2">
                    {/* <Sidebar /> */}
                    <div className=" bg-white/20 rounded-3xl flex flex-col p-2 justify-between w-[250px] flex-shrink-0 overflow-y-scroll">
                        <div className="overflow-y-scroll">
                            <div className=" overflow-auto h-auto">
                                {/* TITLE */}
                                <div className="w-full flex flex-row justify-between p-2 items-center h-9">
                                    <BiFilterAlt size={"2rem"} />
                                    <p className="text-lg font-bold ml-1">Filter</p>
                                    <p className="w-full justify-end flex text-sm">{filteredMetadata.length} results</p>
                                </div>
                                {/* SEARCH */}
                                <div className="flex flex-row items-center w-full pb-2">
                                    <LiaSearchSolid size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                    <input id="Search" className="w-full bg-no-repeat bg-white/20 bg-left-2 h-9 bg-center text-sm rounded-r-full border-0 text-white placeholder:text-white" type="search" placeholder="#1234" value={searchValue} onChange={handleSearchChange}></input>
                                    {searchValue ? <MdClear onClick={clearSearch} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* RARITY */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <LuCrown size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="cursor-pointer w-full focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 text-sm pl-1" value={selectedRarity} onChange={(e) => setSelectedRarity(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Rarities
                                            </option>
                                            {rarityOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedRarity ? <MdClear onClick={handleClearRarity} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* BACKGROUNDS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <PiSelectionBackgroundBold size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectBackground} onChange={(e) => setSelectBackground(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Backgrounds
                                            </option>
                                            {backgroundOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectBackground ? <MdClear onClick={handleClearBackground} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* SHELLS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <BsEgg size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedShell} onChange={(e) => setSelectedShell(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Shells
                                            </option>
                                            {shellOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedShell ? <MdClear onClick={handleClearShell} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* SHELLS EXTRAS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <BsBandaid size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedExtras} onChange={(e) => setSelectedExtras(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Shells Extras
                                            </option>
                                            {shellExtrasOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedExtras ? <MdClear onClick={handleClearExtras} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* WAISTS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <GiBelt size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedWaist} onChange={(e) => setSelectedWaist(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Waists
                                            </option>
                                            {waistOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedWaist ? <MdClear onClick={handleClearWaist} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* RIGHT HANDS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <IoHandRightOutline size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedRightHand} onChange={(e) => setSelectedRightHand(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Right Hands
                                            </option>
                                            {rightHandOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedRightHand ? <MdClear onClick={handleClearRightHand} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* LEFT HANDS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <IoHandLeftOutline size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedLeftHand} onChange={(e) => setSelectedLeftHand(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Left Hands
                                            </option>
                                            {leftHandOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedLeftHand ? <MdClear onClick={handleClearLeftHand} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* NECKS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <GiPearlNecklace size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedNeck} onChange={(e) => setSelectedNeck(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Necks
                                            </option>
                                            {neckOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedNeck ? <MdClear onClick={handleClearNeck} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* HEADS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <PiUserBold size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedHead} onChange={(e) => setSelectedHead(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Heads
                                            </option>
                                            {headOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedHead ? <MdClear onClick={handleClearHead} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* EYES */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <AiOutlineEye size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedEyes} onChange={(e) => setSelectedEyes(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Eyes
                                            </option>
                                            {eyeOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {selectedEyes ? <MdClear onClick={handleClearEyes} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* EYE GEARS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <BsHeadsetVr size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedEyeGear} onChange={(e) => setSelectedEyeGear(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Eye Gear
                                            </option>
                                            {eyeGearOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {selectedEyeGear ? <MdClear onClick={handleClearEyeGear} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* MOUTHS */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <GiLips size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedMouth} onChange={(e) => setSelectedMouth(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Mouths
                                            </option>
                                            {mouthOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    {selectedMouth ? <MdClear onClick={handleClearMouth} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                {/* YOLK TYPES */}
                                <div className="flex flex-row flex-nowrap w-full pb-2">
                                    <div className="hover:bg-white/20 rounded-full flex flex-row flex-nowrap w-full">
                                        <BsEggFried size={"1rem"} className="bg-white/20 h-9 w-9 p-1 pl-2 rounded-l-full flex-shrink-0" />
                                        <select className="w-full cursor-pointer focus:outline-none appearance-none text-white rounded-r-full h-9 bg-white/20 px-4 text-sm pl-1" value={selectedYolkType} onChange={(e) => setSelectedYolkType(e.target.value)}>
                                            <option className=" bg-[#1190a5] text-white" value="">
                                                All Yolk Types
                                            </option>
                                            {yolkTypeOptions.map((option) => (
                                                <option key={option} className=" bg-[#1190a5] text-white" value={option}>
                                                    {option}
                                                </option>
                                            ))}
                                        </select>
                                    </div>

                                    {selectedYolkType ? <MdClear onClick={handleClearYolkType} className="flex cursor-pointer rounded-full w-9 h-9 mx-1 p-1 flex-shrink-0 hover:bg-white/20" /> : null}
                                </div>
                                <button onClick={handleClearAll} className="h-10 bg-white w-full rounded-full text-[#1190a5] hover:bg-white/80 ">
                                    Clear Search
                                </button>
                            </div>
                        </div>
                    </div>
                    {isModalVisible ? (
                        <div onClick={toggleModal} className="w-full h-full bg-white/20 rounded-[32px] p-2">
                            <div className="flex flex-row w-full h-full">
                                <div className=" h-full md:max-w-sm lg:max-w-max">
                                    <img className="w-full h-full md:object-cover " src={`https://s3.wasabisys.com/nfts.edgyeggs.wtf/${selectedNft.edition}.png`} alt={`${selectedNft.name}`} />
                                </div>
                                <div className="w-1/2 h-full flex flex-1 flex-col items-center">
                                    <p className="lg:text-4xl md:text-xl font-bold md:pb-2 lg:py-10">{selectedNft.name}</p>
                                    <div className="flex md:flex-col lg:flex-row md:space-x-0 lg:space-x-8 md:pb-2 lg:pb-10 w-full md:justify-start lg:justify-center md:pl-2">
                                        <div className="flex flex-row items-center space-x-2">
                                            <LuCrown size={"1.5rem"} />
                                            <p className=" md:text-sm lg:text-lg font-bold">Rarity: </p>
                                            <p className="md:text-sm lg:text-lg ">{selectedNft.Rarity}</p>
                                        </div>
                                        <div className="flex flex-row items-center space-x-2">
                                            <TbMilitaryRank size={"1.5rem"} />
                                            <p className="md:text-sm lg:text-lg font-bold">Rank: </p>
                                            <p className="md:text-sm lg:text-lg ">{selectedNft.Rank}</p>
                                        </div>
                                        <div className="flex flex-row items-center space-x-2">
                                            <FaRankingStar size={"1.5rem"} />
                                            <p className="md:text-sm lg:text-lg font-bold">Score: </p>
                                            <p className="md:text-sm lg:text-lg ">{selectedNft.Score}</p>
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-center px-10 w-full">
                                        <div className="flex flex-1 flex-col w-full ">
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Background </p>
                                                    <PiSelectionBackgroundBold className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[0].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap ">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Shell </p>
                                                    <BsEgg className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[1].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Shell Extras </p>
                                                    <BsBandaid className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[2].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Waist </p>
                                                    <GiBelt className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px]" />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[3].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Right Hand </p>
                                                    <IoHandRightOutline className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[4].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Left Hand </p>
                                                    <IoHandLeftOutline className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[5].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Neck </p>
                                                    <GiPearlNecklace className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[6].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Head </p>
                                                    <PiUserBold className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[7].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Eyes </p>
                                                    <AiOutlineEye className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[8].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Eye Gear </p>
                                                    <BsHeadsetVr className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[9].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Mouth </p>
                                                    <GiLips className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[10].value}</p>
                                            </div>
                                            <div className="grid grid-cols-2 items-center space-x-2 w-full">
                                                <div className=" grid-span-1 flex flex-row whitespace-nowrap">
                                                    <p className="md:text-sm lg:text-lg font-bold text-right w-full mr-2">Yolk Type </p>
                                                    <BsEggFried className="md:w-[18px] md:h-[18px] lg:w-[28px] lg:h-[28px] " />
                                                </div>
                                                <p className="grid-span-2 whitespace-nowrap">{selectedNft.attributes[11].value}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <button className="h-10 w-fit px-12 font-bold bg-white rounded-full flex justify-center items-center text-[#1190a5] hover:bg-white/80 mt-4" onClick={toggleModal}>
                                        Close
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <>
                            {isLoading ? (
                                <p className="text-lg font-bold ml-1 flex-1 w-full flex justify-center items-center">Loading...</p>
                            ) : (
                                <>
                                    {filteredMetadata.length === 0 ? (
                                        <p className="text-lg font-bold ml-1 flex-1 w-full flex justify-center items-center">No Edgy Eggs found</p>
                                    ) : (
                                        <>
                                            <div id="cards-container" className="cards-container">
                                                <InfiniteScroll className="grid md:grid-cols-3 lg:grid-cols-6 gap-2" dataLength={filteredMetadata.slice(0, limit).length} hasMore={hasMore} next={fetchMore} /* loader={<h4 style={width}>Loading...</h4>} */ scrollableTarget="cards-container">
                                                    {filteredMetadata.slice(0, limit).map((nft, index) => (
                                                        <div onClick={() => toggleModal(nft)} key={index} className="flex flex-row bg-white/20  cursor-pointer rounded-3xl p-2 relative overflow-hidden">
                                                            <div className="font-bold opacity-0 hover:bg-white/20 hover:opacity-100 absolute z-10 w-full h-full flex justify-center items-end top-0 bottom-0 right-0 left-0 ease-in-out duration-150 transition">
                                                                <div className="h-1/5 bg-[#67b8c5] w-full absolute bottom-0 right-0 left-0 " />
                                                                <p className="z-10 absolute bottom-3 text-lg">{nft.name}</p>
                                                            </div>
                                                            <img className="rounded-[18px] object-fill w-full" src={`https://s3.us-east-1.wasabisys.com/edgyeggs-scaled-images/${nft.edition}-hq-scale-0_25x.jpg`} alt="Edgy Eggs NFTs"></img>{" "}
                                                        </div>
                                                    ))}
                                                </InfiniteScroll>
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default SectionContentEggmarket
