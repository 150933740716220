import React from "react"
import "./SectionContentTeam.css"

const TeamMember = (props) => {
    const imageSource = props.imageSource
    const title = props.title
    const subtitle = props.subtitle
    const description = props.description
    const open = props.open

    return (
        <div className="team-member">
            <img className="team-member__image" alt="Edgy Eggs Team Member" src={imageSource}></img>
            <h3 className="team-member__title">{title}</h3>
            <p className="team-member__subtitle">{subtitle}</p>
            <p className="team-member__desc">{description}</p>
            <button onClick={open}>Read More</button>
        </div>
    )
}

export default TeamMember
